import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CookiesSvg } from '../Image/Svg';
import Cookies from 'js-cookie';
import { rem } from 'polished';

const CookiesComponent = styled.div `
    position: fixed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: ${rem(20)} ${rem(20)} ${rem(20)} ${rem(32)};
    box-shadow: 6px 6px 18px #00000029;
    border-radius: 25px;
    width: 450px;
    height: 197px;
    bottom: 120px;
    right: 50px;
    z-index: 10;

    & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    & > div:nth-child(1) > h2 {
        margin-left: ${rem(10)};
        font: normal normal normal 20px/20px InterExtraBold;
        color: #2536DF;
    }

    & > div:nth-child(2) {
        margin-top: ${rem(15)};
        margin-bottom: ${rem(20)};
    }

    & > div:nth-child(2) > p,
    & > div:nth-child(2) > span,
    & > div:nth-child(3) > button,
    & > div:nth-child(3) > span {
        font: normal normal normal 14px/16px InterRegular;
    }

    & > div:nth-child(3) > button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 30px;
        background: transparent linear-gradient(90deg, #4298E2 0%, #4298E2 0%, #2536DF 100%, #2536DF 100%) 0% 0% no-repeat padding-box !important;
        border: none;
        border-radius: 40px 40px 40px 40px;
        font: normal normal normal 14px/17px InterRegular;
        text-decoration: none;
        padding: 0;
    }

    & > div:nth-child(3) > button span:first-child {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 26px;
        color: #2536DF;
        background: white;
        border-radius: 40px 40px 40px 40px;
        cursor: pointer;
    }

    & > div:nth-child(3) > button:hover span {
        background: transparent linear-gradient(90deg, #4298E2 0%, #4298E2 0%, #2536DF 100%, #2536DF 100%) 0% 0% no-repeat padding-box !important;
        color: white;
        transition: all 0.3s;
    }

    & > div:nth-child(3) > span:last-child {
        margin-left: ${rem(10)};
        text-decoration: underline;
        cursor: pointer;
    }

    & > div:nth-child(3) > span:last-child:hover {
        color: #2536DF;
    }

    @media (max-width: 600px) {
        width: 350px;
        bottom: ${rem(90)};
        right: 25px;
    }
`

const CookiesBanner: React.FC = () => {

    const [getCookieStatus, setGetCookieStatus] = useState(Cookies.get('localConsent'));

    useEffect(() => {
        if (getCookieStatus === "true") {
            const newScriptTest = document.createElement("script");
            newScriptTest.setAttribute('type', 'text/javascript');
            const inlineScriptTest = document.createTextNode(`
                (function(d, s) {
                    var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                    e.defer = true; e.src = "https://www.googletagmanager.com/gtag/js?id=G-EJGPCZ8M0E";
                    t.parentNode.insertBefore(e, t);
                })(document, "script")
            `);
            newScriptTest.appendChild(inlineScriptTest); 
            document.head.appendChild(newScriptTest);

            const newScript = document.createElement("script");
            newScript.setAttribute('type', 'text/javascript');
            const inlineScript = document.createTextNode(`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('consent', 'update', {
                    ad_storage: 'granted',
                    analytics_storage: 'granted',
                  });
                gtag('config', 'G-EJGPCZ8M0E');
            `);
            newScript.appendChild(inlineScript); 
            document.head.appendChild(newScript);
        } else {
            const newScript = document.createElement("script");
            newScript.setAttribute('type', 'text/javascript');
            const inlineScript = document.createTextNode(`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });
                gtag('config', 'G-EJGPCZ8M0E');
            `);
            newScript.appendChild(inlineScript);
            document.head.appendChild(newScript);
        }
    }, [getCookieStatus])

    const askAnnonce = () => {
        const ask = document.getElementById("ask_annonce");
        if (ask) {
            ask.style.bottom = '120px';
        }
    }

    const acceptCookie = () => {
        Cookies.set('localConsent', 'true', {expires: 365, sameSite: 'strict'});
        setGetCookieStatus("true");
        askAnnonce();
    }

    const refuseCookie = () => {
        Cookies.set('localConsent', 'false', {expires: 365, sameSite: 'strict'});
        setGetCookieStatus("false");
        askAnnonce();
    }

    if (getCookieStatus === undefined) {
        return (
            <CookiesComponent id="cookies">
                <div>
                    <CookiesSvg />
                    <h2>Cookies</h2>
                </div>
                <div>
                    <p>Notre site utilise des cookies afin de vous fournir la meilleure expérience possible.</p>
                    <br />
                    <span>Découvrez comment gérer vos cookies</span>
                </div>
                <div>
                    <button onClick={acceptCookie}>
                        <span>
                            Accepter
                        </span>
                    </button>
                    <span onClick={refuseCookie}>Refuser</span>
                </div>
            </CookiesComponent>
        )    
    }
    return null
}

export default CookiesBanner;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Fibre } from './../../asset/image/svg/picto-fibre.svg';
import { ReactComponent as FixePhone } from './../../asset/image/svg/telephone-fixe.svg';
import { ReactComponent as Phone } from './../../asset/image/svg/telephonie-mobile.svg';
import { ReactComponent as DataCenter } from './../../asset/image/svg/datacenter.svg';

import { ReactComponent as Logo } from './../../asset/image/svg/logo-leggit.svg';

import { ReactComponent as Outsourcing } from './../../asset/image/svg/picto-infogerance.svg';
import { ReactComponent as Advice } from './../../asset/image/svg/picto-conseil.svg';
import { ReactComponent as BeSpoke } from './../../asset/image/svg/picto-bespoke.svg';

import { ReactComponent as Network } from './../../asset/image/svg/picto-network.svg';
import { ReactComponent as Server } from './../../asset/image/svg/picto-server.svg';
import { ReactComponent as Cloud } from './../../asset/image/svg/picto-cloud.svg';

import { ReactComponent as Technogym } from './../../asset/image/svg/technogym.svg';
import { ReactComponent as DanielFeau } from './../../asset/image/svg/daniel-féau.svg';
import { ReactComponent as LeonGrosse } from './../../asset/image/svg/leon-grosse.svg';
import { ReactComponent as Esiea } from './../../asset/image/svg/esiea.svg';

import { ReactComponent as CX } from './../../asset/image/svg/3cx.svg';
import { ReactComponent as OpenIp } from './../../asset/image/svg/openip.svg';
import { ReactComponent as Appliwave } from './../../asset/image/svg/appliwave.svg';
import { ReactComponent as Ovh } from './../../asset/image/svg/ovhcloud.svg';
import { ReactComponent as Colt } from './../../asset/image/svg/logo-colt.svg';
import { ReactComponent as Equinix } from './../../asset/image/svg/equinix.svg';

import { ReactComponent as Ask } from './../../asset/image/svg/picto-ask.svg';

import { ReactComponent as Parathese } from './../../asset/image/svg/parenthese-bleue.svg';

import { ReactComponent as Chevron } from './../../asset/image/svg/chevron.svg';

import { ReactComponent as More } from './../../asset/image/svg/more.svg';

import { ReactComponent as Check } from './../../asset/image/svg/check.svg';

import { ReactComponent as Interxion } from '../../asset/image/svg/Interxion-logo.svg';
import { ReactComponent as Sungard } from './../../asset/image/svg/sungard.svg';

import { ReactComponent as Visibility } from './../../asset/image/svg/picto-visibility.svg'
import { ReactComponent as Bug } from './../../asset/image/svg/picto-bug.svg';
import { ReactComponent as Vpn } from './../../asset/image/svg/picto-vpn.svg';
import { ReactComponent as CheckGuard } from './../../asset/image/svg/picto-check.svg';

import { ReactComponent as Smb } from './../../asset/image/svg/picto-SMB.svg'
import { ReactComponent as Performance } from './../../asset/image/svg/picto-performances.svg';
import { ReactComponent as Manage } from './../../asset/image/svg/picto-manages.svg';

import { ReactComponent as Zyxel } from './../../asset/image/svg/logo-zyxel.svg';
import { ReactComponent as Ruckus } from './../../asset/image/svg/logo-ruckus.svg';

import { ReactComponent as WindowServer } from './../../asset/image/svg/logo-windows-server.svg';
import { ReactComponent as Vmware } from './../../asset/image/svg/vmware.svg';

import { ReactComponent as DellEmc } from './../../asset/image/svg/dell_emc.svg';
import { ReactComponent as Synology } from './../../asset/image/svg/logo-synology.svg';

import { ReactComponent as Veeam } from './../../asset/image/svg/logo-veeam.svg';
import { ReactComponent as Acronis } from './../../asset/image/svg/acronis.svg';

import { ReactComponent as Directory } from './../../asset/image/svg/picto-dossier.svg'
import { ReactComponent as Download } from './../../asset/image/svg/picto-download.svg'
import { ReactComponent as ServerPicto } from './../../asset/image/svg/picto-serveur.svg'

import { ReactComponent as Intervention } from './../../asset/image/svg/picto-outils.svg';
import { ReactComponent as Outils } from '../../asset/image/svg/picto-intervention.svg';

import { ReactComponent as Tickets } from '../../asset/image/svg/picto-tickets.svg';

import { ReactComponent as Disponibility } from './../../asset/image/svg/picto-disponibilite.svg';

import { ReactComponent as Roc } from './../../asset/image/svg/picto-roc.svg';
import { ReactComponent as Veille } from './../../asset/image/svg/picto-veille.svg';
import { ReactComponent as Collaboration } from './../../asset/image/svg/picto-collaboration.svg';
import { ReactComponent as Alert } from './../../asset/image/svg/picto-alertes.svg';
import { ReactComponent as Extranet } from './../../asset/image/svg/picto-extranet.svg';

import { ReactComponent as Aws } from './../../asset/image/svg/logo-aws.svg';
import { ReactComponent as Azure } from './../../asset/image/svg/azure.svg';

import { ReactComponent as MicrosoftOffice } from './../../asset/image/svg/logoMicrosoftOffice365.svg';
import { ReactComponent as Office365 } from './../../asset/image/svg/produitsOffice365.svg';
import { ReactComponent as GoogleWorkspace } from './../../asset/image/svg/googleWorkspace.svg';
import { ReactComponent as GoogleProduit } from './../../asset/image/svg/googleProduits.svg';

import { ReactComponent as Fortinet } from './../../asset/image/svg/logo-fortinet.svg';
import { ReactComponent as Cisco } from './../../asset/image/svg/logo-cisco.svg';

import { ReactComponent as Bougie } from './../../asset/image/svg/picto-bougie.svg';

import { ReactComponent as Croix } from './../../asset/image/svg/croix.svg';

import { ReactComponent as Cookie } from './../../asset/image/svg/picto-cookie.svg';

type PropsSvg = {
    onClick?: any;
    id?: string;
    width?: number;
    height?: number;
}

const LogoComponent = styled(Logo) `
    cursor: pointer;
`

export const LogoSvg: React.FC<PropsSvg> = ({id, width, height}) => {
    const navigate = useNavigate();

    return <LogoComponent id={id} width={130} height={23} onClick={() => navigate('/')}/>
}

export const FibreSvg: React.FC<PropsSvg> = () => {
    return (
        <Fibre width={50} height={80} />
    )
}

export const FixePhoneSvg: React.FC<PropsSvg> = () => {
    return (
        <FixePhone width={73} height={63} />
    )
}

export const PhoneSvg: React.FC<PropsSvg> = () => {
    return (
        <Phone style={{marginLeft: '15px'}} width={57} height={67} />
    )
}

export const DataCenterSvg: React.FC<PropsSvg> = () => {
    return (
        <DataCenter width={50} height={68} />
    )
}

export const OutsourcingSvg: React.FC<PropsSvg> = () => {
    return (
        <Outsourcing width={48} height={56} />
    )
}

export const AdviceSvg: React.FC<PropsSvg> = () => {
    return (
        <Advice width={72} height={72} />
    )
}

export const BeSpokeSvg: React.FC<PropsSvg> = () => {
    return (
        <BeSpoke width={52} height={60} />
    )
}

export const NetworkSvg: React.FC<PropsSvg> = () => {
    return (
        <Network width={70} height={70} />
    )
}

export const ServerSvg: React.FC<PropsSvg> = () => {
    return (
        <Server width={50} height={68} />
    )
}

export const CloudSvg: React.FC<PropsSvg> = () => {
    return (
        <Cloud width={80} height={48} />
    )
}

export const TechnogymSvg: React.FC = () => {
    return (
        <Technogym width={170} height={45} />
    )
}

export const DanielFeauSvg: React.FC = () => {
    return (
        <DanielFeau width={191} height={27} />
    )
}

export const LeonGrosseSvg: React.FC = () => {
    return (
        <LeonGrosse width={118} height={56} />
    )
}

export const EsieaSvg: React.FC = () => {
    return (
        <Esiea width={136} height={57} />
    )
}

export const OpenIpSvg: React.FC = () => {
    return (
        <OpenIp width={137} height={45} />
    )
}

export const AppliwaveSvg: React.FC<PropsSvg> = ({width, height}) => {
    return (
        <Appliwave width={width ? width : 169} height={height ? height : 32} />
    )
}

export const OvhSvg: React.FC<PropsSvg> = ({width, height}) => {
    return (
        <Ovh width={width ? width : 182} height={height ? height : 37} />
    )
}

export const ColtSvg: React.FC = () => {
    return (
        <Colt width={96} height={37} />
    )
}

export const CXSvg: React.FC = () => {
    return (
        <CX width={121} height={44} />
    )
}

export const EquinixSvg: React.FC = () => {
    return (
        <Equinix width={193} height={31} />
    )
}

export const AskSvg: React.FC<{width?: number, height?: number}> = ({width, height}) => {
    return (
        <Ask width={width || 43} height={height || 35} />
    )
}

export const ParentheseSvg: React.FC = () => {
    return (
        <Parathese width={42} height={79} />
    )
}

export const ChevronSvg: React.FC<PropsSvg> = ({width, height}) => {
    return (
        <Chevron width={width ? width : 11} height={height ? height : 17} />
    )
}

export const MoreSvg: React.FC = () => {
    return (
        <More width={42} height={58} /> 
    )
}

export const CheckSvg: React.FC = () => {
    return (
        <Check width={15} height={13} />
    )
}

export const InterxionSvg: React.FC = () => {
    return <Interxion width={162} height={28} />
}

export const SungardSvg: React.FC = () => {
    return <Sungard width={192} height={65} />
}

export const VisibilitySvg: React.FC = () => {
    return <Visibility width={120} height={57} />
}

export const BugSvg: React.FC = () => {
    return <Bug style={{marginTop: '8px'}} width={70} height={75} />
}

export const VpnSvg: React.FC = () => {
    return <Vpn style={{marginTop: '8px'}} width={70} height={73} />
}

export const CheckGuardSvg: React.FC = () => {
    return <CheckGuard style={{marginTop: '8px'}} width={70} height={75} />
}

export const SmbSvg: React.FC = () => {
    return <Smb width={80} height={78} />
}

export const PerformanceSvg: React.FC = () => {
    return <Performance width={80} height={81} />
}

export const ManageSvg: React.FC = () => {
    return <Manage width={88} height={57} />
}

export const ZyxelSvg: React.FC = () => {
    return <Zyxel width={120} height={26} />
}

export const RuckusSvg: React.FC = () => {
    return <Ruckus width={106} height={62} />
}

export const WindowServerSvg: React.FC = () => {
    return <WindowServer width={157} height={22} />
}

export const VmwareSvg: React.FC = () => {
    return <Vmware width={121} height={18} />
}

export const DellEmcSvg: React.FC = () => {
    return <DellEmc width={130} height={22} />
}

export const SynologySvg: React.FC = () => {
    return <Synology width={135} height={34} />
}

export const VeeamSvg: React.FC = () => {
    return <Veeam width={130} height={23} />
}

export const AcronisSvg: React.FC = () => {
    return <Acronis width={126} height={26} />
}

export const DirectorySvg: React.FC = () => {
    return <Directory width={55} height={49} />
}

export const DownloadSvg: React.FC = () => {
    return <Download width={52} height={53} />
}

export const ServerPictoSvg: React.FC = () => {
    return <ServerPicto width={59} height={53} />
}

export const InterventionSvg: React.FC = () => {
    return <Intervention width={77} height={78} />
}

export const OutilsSvg: React.FC = () => {
    return <Outils width={54} height={54} />
}

export const TicketsSvg: React.FC = () => {
    return <Tickets width={73} height={60} />
}

export const DisponibilitySvg: React.FC = () => {
    return <Disponibility width={80} height={70} />
}

export const RocSvg: React.FC = () => {
    return <Roc width={54} height={60} />
}

export const VeilleSvg: React.FC = () => {
    return <Veille width={52} height={58} />
}

export const CollaborationSvg: React.FC = () => {
    return <Collaboration width={70} height={70} />
}

export const AlertSvg: React.FC = () => {
    return <Alert width={64} height={65} />
}

export const ExtranetSvg: React.FC = () => {
    return <Extranet width={52} height={52} />
}

export const AwsSvg: React.FC = () => {
    return <Aws width={33} height={20} />
}

export const AzureSvg: React.FC = () => {
    return <Azure width={74} height={21} />
}

export const GoogleWorkspaceSvg: React.FC = () => {
    return <GoogleWorkspace width={119} height={15} />
}

export const GoogleProduitSvg: React.FC = () => {
    return <GoogleProduit width={148} height={32} />
}

export const MicrosoftOfficeSvg: React.FC = () => {
    return <MicrosoftOffice width={81} height={17} />
}

export const Office365Svg: React.FC = () => {
    return <Office365 width={290} height={20} />
}

export const CiscoSvg: React.FC = () => {
    return <Cisco width={121} height={23} />
}

export const FortinetSvg: React.FC = () => {
    return <Fortinet width={178} height={20} />
}

export const BougieSvg: React.FC = () => {
    return <Bougie width={29} height={29} />
}

export const CroixSvg: React.FC<{width?: number, height?: number}> = ({width, height}) => {
    return <Croix width={width ? width :25} height={height ? height : 25} />
}

export const CookiesSvg: React.FC = () => {
    return <Cookie width={26} height={28} />
}